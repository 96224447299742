<template>
  <section
    v-if="order"
    class="invoice-preview-wrapper"
  >
    <b-modal
      v-model="showComment"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Ajouter un commentaire"
    >
      <b-form-textarea
        id="event-description"
        v-model="comment"
        class="mb-1"
      />
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="addComment"
        >
          <span class="align-middle">Enregistrer</span>
        </b-button>
      </div>
    </b-modal>
    <b-row
      v-if="order"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="demo-inline-spacing mb-3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="btn-icon"
                @click="printInvoice"
              >
                <feather-icon
                  icon="PrinterIcon"
                />
                <span class="ml-2 align-middle">Facture</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="btn-icon"
                @click="printExportation"
              >
                <feather-icon
                  icon="PrinterIcon"
                />
                <span class="ml-2 align-middle">Exportation</span>
              </b-button>
            </div>

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img
                    style="width: 40px;"
                    :src="require('@/assets/images/logo/kipli-logo.png')"
                  >

                  <h3 class="text-green invoice-logo">
                    Kipli
                  </h3>
                </div>
                <h6 class="mb-1">
                  Livraison:
                </h6>
                <p class="card-text mb-25">
                  {{ order.shipping.address_1 }}
                </p>
                <p
                  v-if="order.shipping.addres_2"
                  class="card-text mb-25"
                >
                  {{ order.shipping.addres_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order.shipping.postcode }} {{ order.shipping.city }}, {{ order.shipping.state }} {{ order.shipping.country }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_phone')"
                  class="card-text mb-25"
                >
                  {{ order.meta_data.find(m => m.key === '_shipping_phone').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_etage')"
                  class="card-text mb-25"
                >
                  <strong><u>Etage: </u></strong>{{ order.meta_data.find(m => m.key === '_shipping_etage').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_ascenseur')"
                  class="card-text mb-25"
                >
                  <strong><u>Ascenseur: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_ascenseur').value }}
                </p>
                <p
                  v-if="order.meta_data.find(m => m.key === '_shipping_refer_to')"
                  class="card-text mb-25"
                >
                  <strong><u>Connu par: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_refer_to').value }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="d-flex align-item-center">
                  <h4 class="invoice-title">
                    <span><strong><u>Passée le:</u></strong> {{ $moment(order.date_created).locale('fr').format('DD MMMM YYYY') }} à {{ $moment(order.date_created).locale('fr').format('hh:mm') }}</span>
                  </h4>
                </div>
                <h6 class="mb-2">
                  Facturation:
                </h6>
                <h6 class="mb-25">
                  {{ order.billing.first_name }} {{ order.billing.last_name }}
                </h6>
                <p
                  v-if="order.billing.company"
                  class="card-text mb-25"
                >
                  {{ order.billing.company }}
                </p>
                <p
                  v-if="order.billing.email"
                  class="card-text mb-25"
                >
                  {{ order.billing.email }}
                </p>
                <p class="card-text mb-25">
                  {{ order.billing.address_1 }}
                </p>
                <p
                  v-if="order.billing.address_2"
                  class="card-text mb-25"
                >
                  {{ order.billing.address_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order.billing.postcode }} {{ order.billing.city }}, {{ order.billing.state }} {{ order.billing.country }}
                </p>
              </div>
              <div class="mt-md-0 mt-2">
                <div class="d-flex">
                  <h4 class="invoice-title mr-2">
                    <span class="invoice-number">#{{ order.number }}</span>
                  </h4>
                </div>
                <div>
                  <h6 class="mb-2">
                    Détail du paiement:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total:
                        </td>
                        <td><span class="font-weight-bold">{{ order.total }}€</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Type:
                        </td>
                        <td>{{ order.payment_method }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Paiement:
                        </td>
                        <td>{{ order.payment_method_title }}</td>
                      </tr>
                      <tr v-if="order.date_paid">
                        <td class="pr-1">
                          Date de paiement:
                        </td>
                        <td>{{ $moment(order.date_paid).format('DD-MM-YYYY') }}</td>
                      </tr>
                      <tr v-if="order.meta_data.find(m => m.key === '_shipping_fiscal_code') && order.meta_data.find(m => m.key === '_shipping_fiscal_code').value !== ''">
                        <td class="pr-1">
                          Code Fiscal:
                        </td>
                        <td>{{ order.meta_data.find(m => m.key === '_shipping_fiscal_code').value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-card-body>

          <b-row class="mb-4 ml-4">
            <b-col cols="6">
              <p>Expéditeur: </p>
              <v-select
                v-model="logistic"
                placeholder="Choisissez un logisticien"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="logistics"
              />
            </b-col>
          </b-row>

          <!-- Spacer -->
          <!-- <hr class="invoice-spacing"> -->

          <!-- Invoice Client & Payment Details -->

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="order.line_items"
            :fields="['image', 'name', 'quantity', 'total']"
          >
            <template #cell(image)="data">
              <feather-icon
                icon="TrashIcon"
                class="trash text-danger mr-2 cursor-pointer"
                @click="order.line_items = order.line_items.filter((item) => item.variation_id !== data.item.variation_id)"
              />
              <img v-if="order.products.find(i => i._id === data.item.product_id)" :src="order.products.find(i => i._id === data.item.product_id).src" style="width: 90px;">
            </template>
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25">
                <p>
                  {{ data.item.name }}
                </p>
                <span><strong><u>SKU:</u></strong> {{ data.item.sku }}</span>
                <br>
                <span
                  v-for="(meta, key) of data.item.meta_data"
                  :key="key"
                >
                  <span v-if="meta.value"><strong><u>{{ meta.key }}:</u></strong> {{ meta.value }}</span><br>
                </span>
              </b-card-text>
              <div v-if="data.item.meta_data && data.item.meta_data.length > 1">
                <b-card-text
                  v-if="data.item.meta_data.find(m => m.key === 'free_gift_coupon')"
                  class="text-nowrap mb-0"
                >
                  <strong>Code promo:</strong> {{ data.item.meta_data.find(m => m.key === 'free_gift_coupon').value }}
                </b-card-text>
                <b-card-text
                  v-if="data.item.meta_data.find(m => m.key === 'free_product')"
                  class="text-nowrap"
                >
                  <strong>Produit gratuit</strong>
                </b-card-text>
              </div>
            </template>
            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.total || data.item.price }} €
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text
                  v-if="order.coupon_lines.length"
                  class="mb-0"
                >
                  <span class="font-weight-bold">Code promo:</span>
                  <ul>
                    <li
                      v-for="(coupon, key) of order.coupon_lines"
                      :key="key"
                    >
                      <span class="ml-75"><strong>{{ coupon.code }}</strong> : Remise de {{ coupon.discount }}€</span>
                    </li>
                  </ul>
                </b-card-text>
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Livraison:</span>
                  <ul>
                    <li
                      v-for="(shipping, key) of order.shipping_lines"
                      :key="key"
                    >
                      <span class="ml-75"><strong>{{ shipping.method_title }}</strong> : {{ shipping.total }}€</span>
                    </li>
                  </ul>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total (HT):
                    </p>
                    <p class="invoice-total-amount">
                      {{ order.line_items.map(r => Number(r.total)).reduce((a, b) => a + b, 0).toFixed(2) }} €
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Livraison:
                    </p>
                    <p class="invoice-total-amount">
                      {{ order.shipping_lines.map(l => Number(l.total)).reduce((a, b) => a + b, 0).toFixed(2) }} €
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      TVA:
                    </p>
                    <p class="invoice-total-amount">
                      {{ order.line_items.map(r => Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2) }} €
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ order.line_items.map(r => Number(r.total) + Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2) }} €
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ order.customer_note }}</span>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import logistics from '@utils/logistic.json'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      logistics,
      logistic: null,
      order: null,
      showComment: false,
      comment: '',
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  mounted() {
    this.get()
    this.getOrder()
  },
  methods: {
    ...mapActions('admin', ['get']),
    async getOrder() {
      try {
        const { order } = await this.$http.get(`/admin/order/${this.$route.params.id}/dashboard`)
        this.order = order
        this.order.itemsLength = order?.line_items?.length
      } catch (err) {
        console.log(err)
      }
    },
    async printInvoice() {
      try {
        this.order.logisticChoice = this.logistic
        this.order.total = this.order.line_items.map(r => Number(r.total) + Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2)
        this.order.total_tax = this.order.line_items.map(r => Number(r.total_tax)).reduce((a, b) => a + b, 0).toFixed(2)
        this.order.shipping_total = this.order.shipping_lines.map(l => Number(l.total)).reduce((a, b) => a + b, 0).toFixed(2)
        const { link } = await this.$http.post('/admin/invoice-special/', { order: this.order })
        window.open(link)
      } catch (err) {
        console.log(err)
      }
      // window.print()
    },
    async printExportation() {
      try {
        const { link } = await this.$http.post('/admin/invoice-special/exportation/', { order: this.order })
        window.open(link)
      } catch (err) {
        console.log(err)
      }
      // window.print()
    },
    async deleteComment(key) {
      try {
        const comments = this.order.sav.comments.filter((c, i) => i !== key)
        await this.$http.post(`/admin/order/${this.$route.params.id}/deleteComment`, { comments })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async addComment() {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/addComment`, { text: this.comment })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async sav(status) {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/sav`, { status })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Mise à jour réussie.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'primary'
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  .trash {
    display: none;
  }

  .content-header {
    display: none;
  }

  .demo-inline-spacing {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
